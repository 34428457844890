import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Row, Col, Container, Button, ListGroup, Modal } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from "../provider/authProvider";
import { Spinner } from 'react-bootstrap';
import useLocation from '../utils/useLocation';
import HowToClaim from "../components/HowToClaim";
import Footer from "../components/Footer";
import location from "../components/images/location.svg";
import dummyimg from "../components/images/default/couponFallback.png";


const InStoreCouponDetailsPage = () => {
  //URL based on run env
  const apiUrl = process.env.REACT_APP_apiUrl;

  const { merchantID } = useParams();
  const navigate = useNavigate();
  const [randomCoupon, setRandomCoupon] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [imageUrl, setImageUrl] = useState(dummyimg); // Initial image as fallback
  const [isLoading, setIsLoading] = useState(true);

  const { token } = useAuth(); //to get email
  const email = token;

    /**
     * Fetches the presigned URL for the image associated with the coupon.
     */
    const fetchImage = async (objectKey) => {
        try {
        const response = await axios.get(
            `${apiUrl}/GeneratePresignedUrl`,
            {
            params: { objectKey },
            }
        );
        setImageUrl(response.data); // Set the fetched image URL
        } catch (error) {
        console.error("Error fetching image:", error);
        setImageUrl(dummyimg); // Fallback in case of an error
        } finally {
        setIsLoading(false);
        }
    };

  const { forceGetLocation, errorMessage } = useLocation(apiUrl, email);

  useEffect(() => {
    const fetchCoupon = async () => {
      try {
        // Check if merchantID is present
        if (!merchantID) {
          throw new Error('Merchant ID is missing');
        }
        // Prepare the API URL with query params
        const url = `${apiUrl}/QR-Scan`;
        const params = {
          merchantID: merchantID,
          email: email,
        };
  
        // Make the GET request using axios
        const response = await axios.get(url, { params });
        // Check if the response is successful and contains data
        if (response.status === 200 && response.data) {
          setRandomCoupon(response.data); // Set the random coupon data
          fetchImage("CouponImages/"+response.data.merchantID+"/"+response.data.imgName); // Trigger the image fetch on component mount
        } else if (response.status === 204) {
          throw new Error('No active coupons found.');
        } else {
          throw new Error('Unexpected response from server.');
        }
      } catch (error) {
        // Handle errors (network, response issues, etc.)
        if (error.response.status === 403) {
          setAlertMessage({ type: 'danger', text: 'You must be present at the store to scan QR code.'});
        } else {
          setAlertMessage({ type: 'danger', text: error.message });
        }
        setShowModal(true);
      }
    };

    const handleFetchWithLocation = async () => {
      try {
        // Ensure the location is updated before fetching the coupon
        await forceGetLocation();
        // Now fetch the coupon
        await fetchCoupon();
      } catch (error) {
        // Handle errors from forceGetLocation if needed
        setAlertMessage({ type: 'danger', text: error.message });
      }
    };
  
    if(email) {
      handleFetchWithLocation();
    } else {
      fetchCoupon();
    }
  }, [merchantID, email]);

  const addToWallet = async () => {
    try {
      if (!randomCoupon) {
        console.error('No coupon to add to wallet');
        setAlertMessage({ type: 'danger', text: 'No coupon to add to wallet' });
        setShowModal(true);
        return;
      }
  
      if (!email) {
        const cachedCoupon = randomCoupon;
        navigate('/Login', {
          state: {
            afterLoginReturnTo: window.location.pathname,
            cachedCoupon,
          },
        });
        return;
      }
  
      // Construct the URL with query parameters for inStoreCouponID and email
      const url = new URL(`${apiUrl}/AddInStoreCouponToWallet`);
      url.searchParams.append('inStoreCouponID', randomCoupon.inStoreCouponID);
      url.searchParams.append('email', email);
  
      // Perform the POST request
      const response = await axios.post(url.toString(), null, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
  
      if (response.status === 200) {
        setAlertMessage({ type: 'success', text: response.data || 'Coupon added to wallet successfully.' });
        setShowModal(true);
        navigate('/Wallet');
      } else {
        const errorMessage = response.data || 'Something went wrong';
        setAlertMessage({ type: 'danger', text: errorMessage });
        setShowModal(true);
      }
    } catch (error) {
      if (error.response.status === 403) {
        setAlertMessage({ type: 'danger', text: 'You must be present at the store to add this coupon to wallet.'});
      } else {
        setAlertMessage({ type: 'danger', text: error.response.data });
      }
      setShowModal(true);
    }
  };
  
  const navigateToWallet = () => {
    addToWallet();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setAlertMessage(null);
  };

  return (
    <div>
      {isLoading ? (
        <div className="text-center my-5">
          <Spinner animation="border" variant="danger" role="status" />
          <p className="mt-3">Loading coupon...</p>
        </div>
      ) : (
        randomCoupon && (
          <>
            <Container className="mx-auto mb-3 pt-3 coupen-details-wrap">
              <div className="coupen-details-inner-div mx-auto">
                <Modal show={showModal} onHide={handleCloseModal} centered>
                  <Modal.Body className="text-center">
                    <p style={{ color: alertMessage?.type === 'success' ? 'green' : 'red' }}>
                      {alertMessage?.text}
                    </p>
                  </Modal.Body>
                  <Modal.Footer className="justify-content-center border-0">
                    <Button variant="primary" onClick={handleCloseModal}>
                      OK
                    </Button>
                  </Modal.Footer>
                </Modal>
                {randomCoupon && (
                  <>
                    <h1 className="c-detail-title">   {randomCoupon.merchantName}</h1>
                    <Card className="coupon-menu-item" style={{ borderWidth: "0.5px" }}>
                      <Col className="md-4 h-50 px-0 py-0">
                        <Card.Img
                          className="w-100 coupen-image-name"
                          variant="top"
                          src={imageUrl}
                        />
                      </Col>
                      <Card.Body className="pl-0 pr-0">
                        <Row className="ml-1">
                          <Col md={11}>
                            <Card.Title
                              className="c-detail-title"
                              style={{
                                marginTop: "10px",
                              }}
                            >
                              {randomCoupon.couponName}
                            </Card.Title>
                            <Card.Text style={{ fontSize: "14px" }}>
                              {randomCoupon.couponDescription}
                            </Card.Text>
                          </Col>
                        </Row>
                      </Card.Body>
                      <ListGroup className="list-group-flush">
                        <Row className="ml-1 my-auto">
                          <Col className="mt-1" md={12}>
                            <p className="coupen-rate-now" style={{ color: "#e80d0d" }}>
                            NOW ${randomCoupon.offerPrice}
                              <sup className="previous-prize insto-p">
                              In-Store <span className="in-sp-t">${randomCoupon.inStorePrice}</span>
                              </sup>
                            </p>
                            <p className="card-text mt-2 mb-3">
                              <small
                                className="text you-save coupen-detail-save"
                                style={{ color: "#007aff", fontWeight: "600" }}
                              >
                                You save ${randomCoupon.inStorePrice - randomCoupon.offerPrice}
                              </small>
                              <small
                                className="text other-tags coupen-detail-save"
                                style={{ fontWeight: "600"  }}
                              >
                                  {randomCoupon.couponCategory}
                              </small>
                            </p>
                            <div className="location-expire-wrap">
                              {randomCoupon.distanceToMerchant !== "9999.99 KM" && (
                                <div className="location"><span><img src={location} alt="location" /> </span>{randomCoupon.distanceToMerchant}</div>
                              )}
                              <p className="expiry-text">Valid till: {randomCoupon.validTill}</p>
                            </div>
                            <Button
                              className="border-0 px-3"
                              style={{
                                borderRadius: "16px",
                                backgroundColor: "#e80d0d",
                                color: "#ffffff",
                                float: "inline-end",
                                marginRight: "15px",
                              }}
                              onClick={navigateToWallet}
                            >
                              Add to Wallet
                            </Button>
                          </Col>
                        </Row>
                      </ListGroup>
                      <Card.Footer className="bg-white" style={{ fontSize: "14px" }}>
                      </Card.Footer>
                    </Card>
                  </>
                )}
              </div>
            </Container>
          </>
        )
      )}
    
    {/* <Container className="mb-3">
      <Row>
        <a className="mx-auto" href="/"> 
          <Button style={{ background: "#e80d0d", color: "#ffffff", borderColor: "#e80d0d", borderRadius: "16px" }}> 
            Explore Marketplace
          </Button>  
        </a>
      </Row>
    </Container> */}
    <HowToClaim />
    <Footer />
  </div>
  );
};

export default InStoreCouponDetailsPage;
