import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Alert, Modal } from 'react-bootstrap';
import axios from "axios";
import { useNavigate, useLocation } from 'react-router-dom';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import PrivacyPolicy from '../components/PrivacyPolicy';
import TermsAndConditions from '../components/TermsAndConditions';
import logo from "../components/images/banner/coupen-logo-new.svg";
import { useAuth } from "../provider/authProvider"; 
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import useAddToWallet from '../components/hooks/useAddToWallet'

const getDeviceFingerprint = async () => {
  const fp = await FingerprintJS.load();
  const result = await fp.get();
  const deviceID = result.visitorId;
  return deviceID;
};

function SignUpPage() {
  // URL based on run environment
  const apiUrl = process.env.REACT_APP_apiUrl;
  const location = useLocation();
  const navigate = useNavigate();
  const { setToken } = useAuth(); 
  const addToWallet = useAddToWallet(apiUrl);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    otp: '',
    deviceID: '', // Device ID will be populated using FingerprintJS
    latitude: '',
    longitude: '',
  });

  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [validated, setValidated] = useState(false);
  const [showTnC, setShowTnC] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [formByGoogle, setFormByGoogle] = useState(false);

  useEffect(() => { //to prevent handle3rdPartySubmit being called before formData is set
      if (formByGoogle) {
        handle3rdPartySubmit();
        setFormByGoogle(false);  
      }
    }, [formByGoogle]);  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleGetLocation = async () => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setFormData((prevFormData) => ({ ...prevFormData, latitude, longitude }));
          },
          (error) => {
            // Detailed error handling
            switch (error.code) {
              case error.PERMISSION_DENIED:
                setErrorMessage('Location access denied by the user.');
                break;
              case error.POSITION_UNAVAILABLE:
                setErrorMessage('Location information is unavailable.');
                break;
              case error.TIMEOUT:
                setErrorMessage('The request to get user location timed out.');
                break;
              default:
                setErrorMessage('An unknown error occurred while retrieving location.');
                break;
            }
            console.error('Error getting location:', error.message);
          }, 
          { enableHighAccuracy: true } // Enable high accuracy mode
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
        setErrorMessage('Geolocation is not available on your device.');
      }
    };

    // Check and request permission
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted') {
          getLocation();
        } else if (result.state === 'prompt') {
          navigator.geolocation.getCurrentPosition(
            () => getLocation(),
            (error) => {
              if (error.code === error.PERMISSION_DENIED) {
                setErrorMessage('Location permission denied. Please allow location permission to proceed.');
              }
            }
          );
        } else if (result.state === 'denied') {
          setErrorMessage('Location permission denied. Please allow location permission to proceed.');
        }
      }).catch((error) => {
        console.error('Error checking permissions:', error);
        getLocation(); // Fallback to try getting location anyway
      });
    } else {
      getLocation(); // Fallback to try getting location directly
    }
  };

  useEffect(() => {
    handleGetLocation();
    getDeviceFingerprint().then(deviceID => {
      setFormData(prevFormData => ({ ...prevFormData, deviceID: deviceID }));
    });
  }, []);

  const handleSubmit = async (e) => {
    setErrorMessage(false);
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } 

    if (!formData.latitude || !formData.longitude) {
      // Check if latitude and longitude are set
      setErrorMessage('We need your location data to proceed. Please allow location access.');
      handleGetLocation(); // Request location access again
    } else {
      handleGenerateOTP();
    }
    setValidated(true); 
  }; 

  const handle3rdPartySubmit = async () => {
    setErrorMessage(false);

    if (!formData.latitude || !formData.longitude) {
      // Check if latitude and longitude are set
      setErrorMessage('We need your location data to proceed. Please allow location access.');
      handleGetLocation(); // Request location access again
    } else {
      try {
        const response = await axios.post(`${apiUrl}/GoogleSignUp`, formData);
  
        if (response.status === 200) {
          setIsSuccessMessageVisible(true);
          setToken(formData.email);
          setTimeout(() => {
            if (localStorage.getItem('afterLoginReturnTo')) {
              const returnTo = localStorage.getItem('afterLoginReturnTo');
              const cachedCoupon = JSON.parse(localStorage.getItem('cachedCoupon'));
              const token = localStorage.getItem('token')
              localStorage.removeItem('afterLoginReturnTo');
              localStorage.removeItem('cachedCoupon');
              addToWallet(cachedCoupon, returnTo, token);
              //navigate(returnTo, { state: { cachedCoupon } });
            } else {
              navigate('/');
            }
          }, 2000);
        } else {
          setErrorMessage('Signup failed: ' + response.data);
        }
      } catch (error) {
        console.error(error.response.data);
        setErrorMessage(error.response.data);
      }
    }
    setValidated(true); 
  };

  const handleGenerateOTP = async () => {
    setErrorMessage(false);
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      setErrorMessage('Please enter a valid email address to generate OTP.');
      return;
    }
    const OTPUrl = new URL(`${apiUrl}/GenerateOTP`);
    OTPUrl.searchParams.append('email', formData.email);
    try {
      const response = await fetch(OTPUrl.toString(), {
        method: 'POST',
      });
      const responseText = await response.text();
      if (response.ok) {
        setFormData((prevFormData) => ({ ...prevFormData, otp: '' }));
        // Show OTP Validation
        setShowOtpModal(true);
      } else {
        setErrorMessage(responseText);
      }
    } catch (error) {
      setErrorMessage(error.response.data);
    }
  };

  const handleOtpSubmit = async () => { 
    setShowOtpModal(false);
    setShowTnC(true);
  }

  const handleTnCAccept = async () => { 
    setShowTnC(false);
    setShowPrivacyPolicy(true);
  }
    
  const handlePrivacyPolicyAccept = async () => { 
    setShowPrivacyPolicy(false);
    try {
      const response = await axios.post(`${apiUrl}/CustomerSignUp`, formData);

      if (response.status === 200) {
        setIsSuccessMessageVisible(true);
        setToken(formData.email);
        setTimeout(() => {
          if (localStorage.getItem('afterLoginReturnTo')) {
            const returnTo = localStorage.getItem('afterLoginReturnTo');
            const cachedCoupon = JSON.parse(localStorage.getItem('cachedCoupon'));
            const token = localStorage.getItem('token')
            localStorage.removeItem('afterLoginReturnTo');
            localStorage.removeItem('cachedCoupon');
            addToWallet(cachedCoupon, returnTo, token);
            //navigate(returnTo, { state: { cachedCoupon } });
          } else {
            navigate('/');
          }
        }, 2000);
      } else {
        setErrorMessage('Signup failed: ' + response.data);
      }
    } catch (error) {
      console.error(error.response.data);
      setErrorMessage(error.response.data);
    }
  };

  const handleTnCDecline = () => { 
    setShowTnC(false); 
    setErrorMessage('You must accept the Terms & Conditions to proceed.'); 
  };

  const handlePrivacyPolicyDecline = () => { 
    setShowPrivacyPolicy(false); 
    setErrorMessage('You must accept the Privacy Policy to proceed.'); 
  };

  

  return (
    <div className="vh-100 d-flex align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} md={6}>
            <Row className="justify-content-center mb-4">
              <img src={logo} alt="Logo" className='Nav-bar-logo-section' />
            </Row>
            {//<h2 className="text-center">Enter your details to Sign-up</h2>
            }
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicFirstName">
                <Form.Label className="mt-3">First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  First Name is required!
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formBasicLastName">
                <Form.Label className="mt-3">Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Last Name is required!
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label className="mt-3">Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid email.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formBasicPhoneNumber">
                <Form.Label className="mt-3">Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter Phone Number"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid phone number.
                </Form.Control.Feedback>
              </Form.Group>

              <Row className="justify-content-center mt-3">
                <Col xs={12} md={6}>
                  <Button className="w-100 border-0" style={{ backgroundColor: "#e80d0d" }} type="submit">
                    Sign Up
                  </Button>
                  {isSuccessMessageVisible && (
                    <Alert className="mt-2" variant="success">
                      Sign-up successful, redirecting to login...
                    </Alert>
                  )}
                </Col>
              </Row>
            </Form>
            <Row className="justify-content-center my-2">
                OR
            </Row>
            <Container className="py-3" >
              <Row className="justify-content-center">
              <GoogleLogin 
                onSuccess={async (credentialResponse) => {
                  const gCred = jwtDecode(credentialResponse.credential);
                  const gmail = gCred.email;
                  const gFirstName = gCred.given_name;
                  const gLastName = gCred.family_name;
                  setFormData(prevFormData => ({ ...prevFormData, firstName: gFirstName, lastName: gLastName, email: gmail }));
                  setFormByGoogle(true);
                  
                }} 
                onError={() => {
                  setErrorMessage("Google could not authenticate, please try again.");
                  }}
                size='large'
                width={365}
                text='signup_with'
              />
              </Row>
              {/* <Row className="justify-content-center mt-2">
                apple
              </Row> */}
            </Container>
            <Button className="px-0 py-0 mt-3" variant="link" onClick={() => navigate('/Login')}>
              Have an account?, Login
            </Button>
            {errorMessage && (
              <Alert className="mt-2" variant="danger">
                {errorMessage}
              </Alert>
            )}
          </Col>
        </Row>
        <Modal show={showTnC} centered>
          <TermsAndConditions isPopup={true} handleClose={handleTnCDecline} handleAccept={handleTnCAccept}/>
        </Modal>
        <Modal show={showPrivacyPolicy} centered>
          <PrivacyPolicy isPopup={true} handleClose={handlePrivacyPolicyDecline} handleAccept={handlePrivacyPolicyAccept}/>
        </Modal>
        {/* OTP Modal */}
        <Modal show={showOtpModal} onHide={() => setShowOtpModal(false)} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Email Verification</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formBasicOtp" className="mb-3">
              <Form.Label>An OTP has been sent to your email</Form.Label>
              <Form.Control
                name="otp"
                type="text"
                placeholder="Enter OTP"
                value={formData.otp}
                onChange={handleChange}
                required
                style={{ height: '50px', width: '100%' }}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid OTP!  
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowOtpModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleOtpSubmit}>
              Submit OTP
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
}

export default SignUpPage;
