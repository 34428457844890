import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import AuthProvider from "./provider/authProvider";
import { GoogleOAuthProvider } from '@react-oauth/google';

const CLIENT_ID = "879236094275-2kodrsg24h4917n7c1frsn5o9hvv316m.apps.googleusercontent.com";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <AuthProvider>
        <App/>
      </AuthProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

