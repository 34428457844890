import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useAuth } from "../../provider/authProvider";
import axios from 'axios';

const useAddToWallet = (apiUrl) => {
  const navigate = useNavigate();

  const addToWallet = async (randomCoupon, returnTo, email) => {
  
    try {
      if (!randomCoupon) {
        console.error('No coupon to add to wallet');
        throw new Error('failed');
      }
  
      // Construct the URL with query parameters for inStoreCouponID and email
      const url = new URL(`${apiUrl}/AddInStoreCouponToWallet`);
      url.searchParams.append('inStoreCouponID', randomCoupon.inStoreCouponID);
      console.log('email '+ email);
      url.searchParams.append('email', email);
  
      // Perform the POST request
      const response = await axios.post(url.toString(), null, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
  
      if (response.status === 200) {
        navigate('/Wallet');
      } else {
            console.error('Something went wrong');
            throw new Error('failed');
        }
    } catch (error) {
        console.error('Incatch: Something went wrong');
        navigate(returnTo, { state: { randomCoupon } });
    }
  };

  return addToWallet;
};

export default useAddToWallet;
